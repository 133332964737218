import { Box, Button, Container } from '@orbit/ui/core'
import { useEffect, useState } from 'react'
import PageLayout from '../layouts/PageLayout'
import * as styles from '../styles/404.css'

const NotFoundImage = () => {
    return (
        <svg
            width={'100%'}
            height={'100%'}
            viewBox="0 0 856 315"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M215.838 199.796V0.0975342H137.232L0 190.819V258.042H148.43V314.098H215.838V258.042H251.848V199.796H215.838ZM148.43 199.796H63.0169L148.43 78.707V199.796Z"
                fill="#0F1015"
            />
            <path
                d="M819.99 199.796V0.0975342H741.384L604.152 190.819V258.042H752.582V314.098H819.99V258.042H856V199.796H819.99ZM752.582 199.796H667.169L752.582 78.707V199.796Z"
                fill="#0F1015"
            />
            <path
                d="M635.359 314.097C663.179 314.097 685.615 291.669 685.615 263.858C685.615 236.046 663.179 213.617 635.359 213.617C607.538 213.617 585.102 236.046 585.102 263.858C585.102 291.669 608.436 314.097 635.359 314.097Z"
                fill="#0295F5"
            />
            <path
                d="M428.442 314.098C472.682 314.098 509.843 298.933 539.926 268.603C570.01 238.274 585.051 200.808 585.051 155.313C585.051 112.495 570.01 75.9214 539.042 45.5919C508.959 15.2623 470.912 0.0975342 427.557 0.0975342C384.202 0.0975342 346.156 15.2623 316.073 45.5919C285.99 75.9214 270.949 113.387 270.949 157.098C270.949 201.7 285.99 239.166 316.958 269.495C346.156 298.933 384.202 314.098 428.442 314.098ZM427.557 70.5691C475.336 70.5691 513.383 109.819 513.383 157.098C513.383 205.268 474.452 244.518 427.557 244.518C380.663 244.518 341.732 205.268 341.732 157.99C340.848 108.927 379.778 70.5691 427.557 70.5691Z"
                fill="#0F1015"
            />
        </svg>
    )
}

const English404 = () => {
    return (
        <Box extraClassName={styles.ContentWrapper} flex={1}>
            <Box flex={5} alignSelf="center">
                <p
                    style={{
                        margin: 0,
                        fontWeight: 600,
                        fontSize: '1.25rem',
                        letterSpacing: '0.125rem',
                    }}
                >
                    IT seems to be teasing us there, but don&apos;t worry, our
                    experts have put together some solutions for you.
                </p>
            </Box>
            <Box flex={3}>
                <ul className={styles.ListStyle}>
                    <li>
                        <Button
                            variant="text"
                            color="primary"
                            href="/english"
                            icon="ArrowRight"
                        >
                            Go to frontpage
                        </Button>
                    </li>
                    <li>
                        <Button
                            variant="text"
                            color="primary"
                            href="/english/search"
                            icon="ArrowRight"
                        >
                            Test out the search
                        </Button>
                    </li>
                    <li>
                        <Button
                            variant="text"
                            color="primary"
                            href="https://verslun.origo.is"
                            icon="ArrowRight"
                        >
                            Go to the store
                        </Button>
                    </li>
                </ul>
            </Box>
        </Box>
    )
}

const Icelandic404 = () => {
    return (
        <Box extraClassName={styles.ContentWrapper} gap={'2.5rem'}>
            <Box flex={5} alignSelf="center">
                <p
                    style={{
                        margin: 0,
                        fontWeight: 600,
                        fontSize: '1.25rem',
                        letterSpacing: '0.125rem',
                    }}
                >
                    Þarna virðist upplýsingatæknin vera að stríða okkur en engar
                    áhyggjur, sérfræðingar okkar hafa sett saman nokkrar lausnir
                    handa þér.
                </p>
            </Box>
            <Box flex={3}>
                <ul className={styles.ListStyle}>
                    <li>
                        <Button
                            variant="text"
                            color="primary"
                            href="/"
                            icon="ArrowRight"
                        >
                            Fara á forsíðu
                        </Button>
                    </li>
                    <li>
                        <Button
                            variant="text"
                            color="primary"
                            href="/leitarnidurstodur"
                            icon="ArrowRight"
                        >
                            Prófaðu leitina
                        </Button>
                    </li>
                    <li>
                        <Button
                            variant="text"
                            color="primary"
                            href="https://verslun.origo.is"
                            icon="ArrowRight"
                        >
                            Skoða vörur í Netverslun
                        </Button>
                    </li>
                </ul>
            </Box>
        </Box>
    )
}

export default function Custom404() {
    const [lang, setLang] = useState('')

    useEffect(() => {
        if (window) {
            setLang(
                window.location.pathname.indexOf('/english') > -1
                    ? 'en-gb'
                    : 'is'
            )
        }
    }, [])

    return (
        <PageLayout menu={null} lang={lang === 'is' ? 'is' : 'en-gb'}>
            <Container>
                <Box extraClassName={styles.NotFoundWrapper}>
                    <Box boxStyles={{ gridColumn: '3 / 11' }}>
                        <NotFoundImage />
                    </Box>
                    <Box boxStyles={{ gridColumn: '3 / 11' }}>
                        {lang === 'en-gb' ? <English404 /> : <Icelandic404 />}
                    </Box>
                </Box>
            </Container>
        </PageLayout>
    )
}
